import * as React from "react"
import "../style/roadmap.css"
import secret from "../images/Secret.png"
import r1 from "../images/r1.png"
import r2 from "../images/r2.png"
import r3 from "../images/r3.png"
import r4 from "../images/r4.png"
import r5 from "../images/r5.png"
import r6 from "../images/r6.png"
import r7 from "../images/r7.png"
import r8 from "../images/r8.png"

const Roadmap = () => {
  return (
    <div className="roadmap" id="roadmap">
        <div>
            <img className="r-img r-secret" src={secret} />
        </div>
        <div>
            <img className="r-img r-1" src={r1} />
        </div>
        <div>
            <img className="r-img r-2" src={r2} />
        </div>
        <div>
            <img className="r-img r-3" src={r3} />
        </div>
        <div>
            <img className="r-img r-4" src={r4} />
        </div>
        <div>
            <img className="r-img r-5" src={r5} />
        </div>
        <div>
            <img className="r-img r-6" src={r6} />
        </div>
        <div>
            <img className="r-img r-7" src={r7} />
        </div>
        <div>
            <img className="r-img r-8" src={r8} />
        </div>
    </div>
  )
}

export default Roadmap
