import * as React from "react"
import HomeBanner from "../components/home-banner.js"
import Footer from "../components/footer.js"
import "../style/style.css"
import Menu from "../components/menu.js"
import Roadmap from "../components/roadmap.js"
import FixedArrow from "../components/fixed-arrow.js"

const IndexPage = () => {
  return (
    <main>
      <Menu></Menu>
      <HomeBanner />
    </main>
  )
}

export default IndexPage
