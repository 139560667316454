import * as React from "react"
import "../style/deye.css"
import deye0 from "../images/D-eye_0.png"
import deye1 from "../images/D-eye_1.jpg"
import deye2 from "../images/D-eye_2.jpg"

const Deye = () => {
  return (
    <div className="deye" id="deye">
        <div>
            <img className="deye-img de-1" src={deye0} />
        </div>
        <div>
            <img className="deye-img de-2" src={deye1} />
        </div>
        <div>
            <img className="deye-img de-3" src={deye2} />
        </div>
    </div>
  )
}

export default Deye
