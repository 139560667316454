import * as React from "react"
import "../style/homebanner.css"
import dtd from "../images/DTD.png"
import deathDylan from "../images/Death_Dylan.png"
import timer from "../images/Timer.png"
import toy from "../images/Toy.png"
import bottle from "../images/Bottle.png"
import eye from "../images/Eye.png"
import ax from "../images/Ax.png"
import mug from "../images/Mug.png"
import intro from "../images/Intro.png"
import introMobile from "../images/Intro_mobile.png"
import Roadmap from "../components/Roadmap"
import Deye from "../components/Deye"

class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.hero = null;

    if(typeof window !== "undefined"){
      window.scrollToDTD = this.scrollTo
    }
  }

  setHeroNode = (elem) => {
    if(elem != null){
      this.hero = elem
    }
  }

  handleScroll = () => {
    if(this.hero != null){
      var parent = this.hero.parentNode
      var content = this.hero.children[0]

      var topBegin = parent.offsetTop
      var screenH = window.innerHeight
      var screenW = window.innerWidth
      var finalGap = screenH
      var topEnd = topBegin + parent.clientHeight - screenH
      var scroll = window.pageYOffset

      var perc = scroll < topBegin ? 0 : (scroll - topBegin) / (topEnd - topBegin)
      perc = perc * 100
      perc = perc > 100 ? 100 : perc
      //perc = perc * 100 * (screenW > 1024 ? 5 : 2)

      content.style = "transform: translate(-" + perc + "%, 0)"
    }
  }

  scrollTo = (id) => {
    var elem = document.getElementById(id)
    var parent = this.hero.parentNode
    var content = this.hero.children[0]

    var perc = parseInt(window.getComputedStyle(elem,null).getPropertyValue("left").split("px")[0]) / content.getBoundingClientRect().width
    var px = window.document.body.offsetHeight * perc
    window.scrollTo({
      top: px,
      left: 0,
      behavior: 'auto'
    })
    console.log(px,perc, window.scrollMaxY);
  }

  click = 3
  secretAlert = () => {
    this.click = this.click - 1
    if(this.click == 0){
      alert("A.D. 1747, V.O.L.T.A.’s secret convention. This is where the ark’s fate was decided")
      this.click = 3
    }
  }

  componentDidMount(){
    window.addEventListener("scroll", this.handleScroll)

    var hash = window.location.hash.substr(1);
    var id = hash.substr(1)
    if(hash != "" && id != null){
      if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
          setTimeout(() => this.scrollTo(id), 1000);
      } else {
          //document.addEventListener("DOMContentLoaded", this.scrollTo(el));
      }
    }
  }

  render(){
    return (
      <div className="homebanner">
        <div className="hb-hero" id="hb-hero" ref={this.setHeroNode}>
          <div className="hb-hero-contant">
            <div className="hb-title">
              <img src={dtd}></img>
              <div className="mint-btn-div">
                <a href="https://mint.dtdnft.com/" className="text button red-background mint-btn">
                  <span>MINT SOLD OUT</span>
                </a>
              </div>
            </div>
            <div className="hb-toy">
              <div className="hb-toy-content" style={{backgroundImage: `url(${toy})`}}></div>
            </div>
            <div className="hb-timer">
              <div className="hb-timer-content" style={{backgroundImage: `url(${timer})`}}></div>
            </div>
            <div className="hb-dylan">
              <div className="hb-dylan-content" style={{backgroundImage: `url(${deathDylan})`}}></div>
            </div>
            <div className="hb-bottle">
              <div className="hb-bottle-content" style={{backgroundImage: `url(${bottle})`}}></div>
            </div>
            <div className="hb-description">
              <img src={intro} />
              <img src={introMobile} />
            </div>
            <div className="hb-eye">
              <div className="hb-eye-content" style={{backgroundImage: `url(${eye})`}}></div>
            </div>
            <div className="hb-ax">
              <div className="hb-ax-content" style={{backgroundImage: `url(${ax})`}}></div>
            </div>
            <div className="hb-mug">
              <hidden dangerouslySetInnerHTML={{__html: "<!--- \n\n\Hi there! You are very cunning! Your attention will pay back! Find our secret mex! Just only 3 clicks!\n\n\n -->"}}></hidden>
              <div className="hb-mug-content" style={{backgroundImage: `url(${mug})`}} onClick={this.secretAlert}></div>
            </div>

            <Roadmap />
            <Deye />
          </div>
        </div>
      </div>
    )
  }
}

export default HomeBanner
